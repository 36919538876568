<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="loading"></v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h4 class="">Настройка отображения</h4>
            <v-switch
              label="Показывать в приложении"
              v-model="point.show_app"
              @change="change_show"
            ></v-switch>
          </v-col>
          <v-col cols="12">
            <h4>Настройка тестирования</h4>
            <v-switch
              label="Только для тестирования"
              v-model="point.testing_app"
              @change="change_testing"
            ></v-switch>
          </v-col>
          <v-col cols="12">
            <h4 class="pl-3">Тарифы</h4>
            <v-data-table :headers="tariff_headers" :items="point_tariffs">
              <template v-slot:item.actions="{ item }">
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
            <v-btn @click="add_tariff">Добавить тариф</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="tariff_select"
        >
          <template v-slot:default>
            <v-card>
              <v-toolbar color="primary" dark>Добавить тариф</v-toolbar>
              <v-card-text>
                <v-select
                  :items="tariffs"
                  v-model="selected_tariff"
                  label="Выберите тариф"
                  item-text="name"
                  item-value="id"
                  :error-messages="select_error"
                  :error="select_error != null"
                  @change="clear_error"
                ></v-select>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  @click="select_tariff"
                  color="primary"
                  :disabled="selected_tariff == null"
                  >Добавить</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn @click="close_select">Закрыть</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog v-model="show_alert" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Подтвердите удаление
          </v-card-title>

          <v-card-text>
            <div class="">
              Вы собираетесь удалить последний тариф на зарядной станции. После
              этого станция будет недоступна для клиентов, пока не будет
              добавлен хотябы один тариф
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="warning" text @click="confirm_remove_last">
              Удалить
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close_alert"> Закрыть </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "CPconfig",
  data: () => {
    return {
      tariff_select: false,
      selected_tariff: null,
      select_error: null,
      show_alert: false,
      tariff_headers: [
        {
          text: "Название",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Цена, р",
          align: "start",
          sortable: false,
          value: "cost",
        },
        {
          text: "Время начала действия",
          align: "start",
          sortable: false,
          value: "operates_from",
        },
        {
          text: "Время окончания действия",
          align: "start",
          sortable: false,
          value: "operates_to",
        },
        {
          text: "Фиксированый",
          align: "start",
          sortable: false,
          value: "fixed",
        },
        { text: "Удалить", value: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.$store.dispatch("load_point_info", {
      point_id: this.$route.params.point_id,
      query: {},
    });
    this.load_point_tariffs();
    this.load_tariffs();
  },
  computed: {
    loading() {
      return this.$store.getters.INFO_LOADING;
    },
    tariffs_loading() {
      return this.$store.getters.POINT_TARIFFS_LOADING;
    },
    point_tariffs() {
      return this.$store.getters.POINT_TARIFFS;
    },
    tariffs() {
      const db_tariffs = this.$store.getters.TARIFFS;
      this.point_tariffs.forEach((p_tariff) => {
        const index = db_tariffs.findIndex(
          (tariff) => tariff.id === p_tariff.id
        );
        if (index > -1) {
          db_tariffs.splice(index, 1); // 2nd parameter means remove one item only
        }
      });
      return db_tariffs.map((tariff) => ({
        name:
          tariff.name + " " + tariff.operates_from + " - " + tariff.operates_to,
        id: tariff.id,
        operates_from: tariff.operates_from,
        operates_to: tariff.operates_to,
      }));
    },
    point() {
      return this.$store.getters.POINT_INFO;
    },
  },
  methods: {
    go_back() {
      this.$router.push({
        name: "point_info",
        params: this.$route.params.point_id,
      });
    },
    load_point_tariffs() {
      this.$store.dispatch("load_point_tariffs", this.$route.params.point_id);
    },
    load_tariffs() {
      this.$store.dispatch("load_tariffs", {
        sortBy: ["id"],
        sortDesc: [false],
        page: 1,
        itemsPerPage: -1,
      });
    },
    findIntersection() {
      const selected_tariff = this.tariffs.find(
        (tariff) => tariff.id === this.selected_tariff
      );
      return this.point_tariffs.find(
        (interval) =>
          selected_tariff.operates_from < interval.operates_to &&
          interval.operates_from < selected_tariff.operates_to
      );
    },
    add_tariff() {
      this.tariff_select = true;
    },
    close_select() {
      this.tariff_select = false;
      this.selected_tariff = null;
      this.clear_error();
    },
    clear_error() {
      this.select_error = null;
    },
    select_tariff() {
      const intersect = this.findIntersection();
      if (intersect !== undefined) {
        this.select_error = "Выбраный тариф пересекается с существующим";
        return;
      }

      this.$store
        .dispatch("add_point_tariff", this.selected_tariff)
        .then(() => {
          this.selected_tariff = null;
          this.tariff_select = false;
          this.load_point_tariffs();
        })
        .then(() => {
          this.load_tariffs();
        });
    },
    deleteItem(item) {
      if (this.point_tariffs.length === 1) {
        this.show_alert = true;
        return;
      }
      this.$store
        .dispatch("delete_point_tariff", item.id)
        .then(() => {
          this.$store.dispatch(
            "load_point_tariffs",
            this.$route.params.point_id
          );
        })
        .then(() => {
          this.$store.dispatch("load_tariffs", {
            sortBy: ["id"],
            sortDesc: [false],
            page: 1,
            itemsPerPage: -1,
          });
        });
    },
    close_alert() {
      this.show_alert = false;
    },
    confirm_remove_last() {
      this.show_alert = false;
      this.$store
        .dispatch("delete_point_tariff", this.point_tariffs[0].id)
        .then(() => {
          this.load_point_tariffs();
        })
        .then(() => {
          this.load_tariffs();
        });
    },
    change_show() {
      if (this.point.testing_app) {
        this.point.testing_app = false;
      }
      const point = Object.assign({}, this.point);
      delete point.evse;
      point.gps = point.latitude + " " + point.longitude;
      this.$store.dispatch("update_point", point);
    },
    change_testing() {
      if (this.point.show_app) {
        this.point.show_app = false;
      }
      const point = Object.assign({}, this.point);
      delete point.evse;
      point.gps = point.latitude + " " + point.longitude;
      this.$store.dispatch("update_point", point);
    },
  },
};
</script>

<style scoped></style>
